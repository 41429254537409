
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  props: {
    source: { type: String, required: true },
    proxyDomain: { type: String },
  },
  setup() {
    const getTitle = (source: string) => {
      let title
      if (source === 'csv') {
        title = 'Order has been imported via csv'
      } else {
        title = 'Order has been created via API'
      }
      return title
    }

    const getType = (source: string) => {
      return source === 'csv' ? 'success' : 'primary'
    }

    return { getTitle, getType }
  },
})
