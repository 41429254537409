
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  props: {
    fullName: { type: String },
    address1: { type: String },
    address2: { type: String },
    city: { type: String },
    province: { type: String },
    zipCode: { type: String },
    countryCode: { type: String },
    phone: { type: String },
    email: { type: String },
  },
  setup() {
    return {}
  },
})
