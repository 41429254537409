
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  props: {
    isDropship: { type: String, required: true },
    isTM: { type: String },
    isFK: { type: String },
  },
  setup() {
    return {}
  },
})
