
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  props: {
    proxyDomain: { type: String },
    proxyId: { type: String },
    proxyType: { type: String },
  },
  setup() {
    return {}
  },
})
