
import { defineComponent, ref, reactive } from 'vue'
import { useNotification } from '@/composables'
import UploadFiles from '@/components/common/UploadFiles.vue'
import { downloadFile } from '@/utils/file'

interface FileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  url?: string
  preview?: string
  originFileObj?: any
  file: string | Blob
  raw: string | Blob
}

export default defineComponent({
  components: {
    UploadFiles,
  },
  props: {
    title: { type: String },
    template: { type: String, default: '#' },
    guide: { type: String },
    accept: { type: String },
    multiple: { type: Boolean, default: true },
  },
  setup(props, context) {
    let dialogVisible = ref(false)
    const resource = 'import'
    const url = resource
    const isShowTemplate = ref(true)
    const { error, success, warning } = useNotification()

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const state = reactive({
      isLoading: false,
    })

    const fileList = ref<FileItem[]>([])

    const onChangeFiles = (files: any) => {
      fileList.value = files
    }

    const close = () => {
      fileList.value = []
      dialogVisible.value = false
    }

    const onSubmit = async () => {
      state.isLoading = true
      if (!fileList.value || fileList.value.length === 0) {
        warning('Please import your file!')
        return
      }
      let file = fileList.value[0].raw as any
      if (!file.name?.endsWith(props.accept)) {
        error(`Wrong file! Please import ${props.accept} file.`)
        return
      }

      context.emit('onAfterSubmitFile', {
        file,
        fileList,
      })
      close()
      state.isLoading = false
    }

    return {
      url,
      isShowTemplate,
      toggle,
      dialogVisible,
      onSubmit,
      onChangeFiles,
      state,
      close,
      downloadFile,
    }
  },
})
