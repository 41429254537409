import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createBlock(_component_el_tag, {
    effect: "dark",
    class: "rounded",
    size: "small",
    type: _ctx.isFK === true ? 'warning' : _ctx.isDropship === true ? 'primary' : 'info'
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.isFK === true ? 'FK' : _ctx.isDropship === true ? 'DROP' : 'POD'), 1)
    ]),
    _: 1
  }, 8, ["type"]))
}